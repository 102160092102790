import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'ARYA',
    lpAddresses: {
      97: '0xEcE7823C425837b90083f2172587F9C94FDA3314',
      56: '0x3fD19A315f9fa09D2A3a0E09A48fd9A52ef4Ac32',
    },
    tokenSymbol: 'ARYA',
    tokenAddresses: {
      97: '0x328afB505b3d5919036786C33E9C42e5a94A6875',
      56: '0x1ade17b4b38b472b5259bbc938618226df7b5ca8',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'ARYA-BUSD LP',
    lpAddresses: {
      97: '0xEcE7823C425837b90083f2172587F9C94FDA3314',
      56: '0x3fD19A315f9fa09D2A3a0E09A48fd9A52ef4Ac32',
    },
    tokenSymbol: 'ARYA',
    tokenAddresses: {
      97: '0x328afB505b3d5919036786C33E9C42e5a94A6875',
      56: '0x1ade17b4b38b472b5259bbc938618226df7b5ca8',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 2,
    risk: 5,
    lpSymbol: 'ARYA-BNB LP',
    lpAddresses: {
      97: '0xa20DCB24e0e178FC5f50d1fdbe4ada813B9D9d82',
      56: '0x39354097e90c1de31F205b115453f6A82b09EF89',
    },
    tokenSymbol: 'ARYA',
    tokenAddresses: {
      97: '0x328afB505b3d5919036786C33E9C42e5a94A6875',
      56: '0x1ade17b4b38b472b5259bbc938618226df7b5ca8',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

]

export default farms
