import axios from 'axios'



(async () => {
//   const baseUrl = 'http://localhost:2743'
const baseUrl = "https://jugalswapapi.wealwin.com"

   
   axios.post(`${baseUrl}/api/getifo`)
      .then(function (response) {
        console.log(response.data.data,"========axios get");
        const datas = response.data.data
        localStorage.setItem("IFOLIST",JSON.stringify(datas));
        axios.get(`${baseUrl}/api/updatestatus`)
	      .then(function (responsesd) {
	      console.log()
	        })
        })
})();

export default {}
