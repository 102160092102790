import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap-libs/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
      font-family: 'Open Sans', sans-serif;
      font-weight: 600 !important;
  }
  body {
    background: ${({ theme }) => theme.colors.background};    

    img {
      height: auto;
      max-width: 100%;
    }
  }
  h1 {
    font-size: 32px !important;
    font-weight: 700 !important;
  }
  nav {
    background-color: #242d5e !important;
  }
  button.hambutton {
    background: #eee;
  }
  .connect_btn {
    background-color: #8e3f43;
  }
  .connect_btn:hover {
    background-color: #763134 !important;
  }
  .vision_card_panel {
    max-width: 400px;
    margin: 10px auto 0;
  }
  .cakeprice {
    margin-bottom: 12px;
  }
  .unlock_btn {
    background-color: #8e3f43;
  }
  .unlock_btn:hover {
    background-color: #6a282b !important;
  }
  .padding_left {
    padding-left: 30px;
  }
  .ifo_btn a {
    color: #8e3f43;
  }
  .ifo_btn a svg {
    fill: #8e3f43;
  }
  img.info_card_bg {
    position: absolute;
    top: 0;
    height: 120px;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
`

export default GlobalStyle
