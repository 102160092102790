export default {
  cake: {
    56: '0x1ade17b4b38b472b5259bbc938618226df7b5ca8',
    97: '0xAe2fAC1C4f99b75c74D422C315093e464Faf350D',
  },
  masterChef: {
    56: '0x822Cdbc3D50d4e993227C9f31A6E1B2f688861F5',
    97: '0xD00D731508bdE6BA4E24D3FfD7Ce34B9Df91c2Bd',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0xBaE36c04b80FE01bb1611160B199fACB7E3CdC27',
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F',
  },
}
