import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://jugalswap-exchange.pages.dev/exchange',
      },
      {
        label: 'Liquidity',
        href: 'https://jugalswap-exchange.pages.dev/liquidity',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/pools',
  },
  {
    label: 'IFO',
    icon: 'IfoIcon',
    href: '/ifo',
  },
  // {
  //   label: 'Margin Trading',
  //   icon: 'TradeIcon',
  //   href: '#',
  // },
  // {
  //   label: 'Positions',
  //   icon: 'InfoIcon',
  //   href: '#',
  // },
  // {
  //   label: 'Lending',
  //   icon: 'IfoIcon',
  //   href: '#',
  // },
  // {
  //   label: 'Info',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'CoinGecko',
  //       href: '#',
  //     },
  //     {
  //       label: 'CoinMarketCap',
  //       href: '#',
  //     },
  //     {
  //       label: 'How it works',
  //       href: '#',
  //     },
  //   ],
  // },
  // {
  //   label: 'Audited by CertiK',
  //   icon: 'AuditIcon',
  //   href: '#',
  // },
]

export default config
